import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";

export const MasterHoldTargetDroOpts = () => {

    const getTargetModel = () => {
        let items = [ChargeConstantCollection.dtlSate.MH, ChargeConstantCollection.dtlSate.MT];

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getTargetModel        
    }
}