import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { useMasterHoldMaintenanceVM } from "presentation/hook/MasterHold/useMasterHoldMaintenanceVM";
import MasterHoldTitleBar from "./MasterHoldTitleBar";
import MasterHoldTablePanel from "./Table/MasterHoldTablePanel";

const MasterHoldMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const masterHoldVM = useMasterHoldMaintenanceVM();
    const {isBackFromDetail} = masterHoldState;    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    masterHoldVM.loadDropdownOption(),
                    masterHoldVM.searchMasterHold()
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [isBackFromDetail, masterHoldVM])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${false ? '' : ' im-hide-side-form-draggable'}`}>
           <MasterHoldTitleBar/>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<MasterHoldTablePanel/>}
                //rightChildren={<MasterHoldSearchPanel/>} 
                />
        </div>
    </>
}

export default memo(MasterHoldMaintenance);