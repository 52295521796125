import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { MasterHoldRepoImpl } from "domain/repository/MasterHold/MasterHoldRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { MasterHoldMaintenanceVM } from "presentation/viewModel/MasterHold/MasterHoldMaintenanceVM";
import { useMemo } from "react";

export const useMasterHoldMaintenanceVM = () => {
    const [, setMasterHoldMaintenanceState] = useMasterHoldMaintenanceTracked();
    const masterHoldMainVM = useMemo(() =>
        MasterHoldMaintenanceVM({
            dispatch: [setMasterHoldMaintenanceState],
            repo: MasterHoldRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setMasterHoldMaintenanceState])

    return masterHoldMainVM
}

