import { MasterHoldMaintenanceConstant } from "presentation/constant/MasterHold/MasterHoldMaintenanceConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useMasterHoldMaintenanceVM } from "presentation/hook/MasterHold/useMasterHoldMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const MasterHoldTitleBar:React.FC = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const masterHoldVM = useMasterHoldMaintenanceVM();
    const messageBarVM = useMessageBarVM();    
    const {isShowDeleteModal, selectedRows} = masterHoldState;
    const MASTER_HOLD_CONSTANT = MasterHoldMaintenanceConstant.Header;
    const [ isLoading, setLoading ] = useState<boolean>(false);

    const handleAdd = useCallback(() => {
        masterHoldVM.onAddClick();
    }, [masterHoldVM]);

    const handleDeleteClick = useCallback(() => {
        if (selectedRows.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to delete.");
            return;
        }
        masterHoldVM.onDeleteClick();
    }, [masterHoldVM, messageBarVM, selectedRows.length]);

    const handleDelete = useCallback(() => {
        setLoading(true);
        masterHoldVM.onDelete(selectedRows).then(()=>{
            masterHoldVM.searchMasterHold().then(()=>{
                setLoading(false);
            }).catch((error) => {                    
                setLoading(false)
            })
        }).catch((error)=>{
            setLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [masterHoldVM, messageBarVM, selectedRows]);

    const handleCancel = useCallback(() => {
        masterHoldVM.onCancelDelete();
    }, [masterHoldVM])

    const handleApply = useCallback(async () => {
        /*if(_.isEmpty(updatedRows)) return;
        setLoading(true);
        const res = await tariffCodeVM.onApply(updatedRows);
        setLoading(false);
        if(!!!res || !res.success){
            messageBarVM.showWarining('Apply failed.');
        }else{
            await tariffCodeVM.searchTariffCode({});
        }
        setLoading(false);*/
    }, []);

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{MASTER_HOLD_CONSTANT.TITLE}</HeaderTitle>
        {(masterHoldState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
       
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} /> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={WorkspaceConstant.Common.BUTTON_ADD} onClick={handleAdd}/>
            <IconButton fileName='Icon-trash' disabled={false} size='medium' toolTipText={WorkspaceConstant.Common.BUTTON_DELETE} onClick={handleDeleteClick}/>
            <div className="add-seperator"/>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={false} onClick={handleApply} />

            <ConfirmDeleteModal headerText={MASTER_HOLD_CONSTANT.TITLE} 
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDeleteModal}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete} />
        </StyledAction>
    </Sidebarheader>
}

export default memo(MasterHoldTitleBar);