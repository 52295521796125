import { SelectionChangedEvent } from "ag-grid-community";
import { MasterHoldEntity } from "domain/entity/MasterHold/MasterHoldEntity";
import { INITIAL_MASTER_HOLD_COL_DEF, transferRowData } from "presentation/constant/MasterHold/MasterHoldMaintenanceColumnDefinition";
import { useMasterHoldMaintenanceVM } from "presentation/hook/MasterHold/useMasterHoldMaintenanceVM";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const MasterHoldTablePanel:React.FC = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const masterHoldVM = useMasterHoldMaintenanceVM();
    const gridRef: any = useRef(null);
    const {tableData} = masterHoldState;
     const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    
    // useEffect(() => {
    //     const columnDefs = (INITIAL_TARIFF_CODE_COL_DEF.slice());

    //     if (!selectedRows || selectedRows.length <= 0) {
    //         gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    //         if (!onTableSelectionClicked) {
    //             gridRef.current?.gridRef.current.api?.deselectAll();
    //         }
    //     }
    // })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {  
        setOnTableSelectionClicked(true);   
        const selectedRows = e.api.getSelectedRows();

        masterHoldVM.updateSelectedRows(tableData,selectedRows);
      }, [masterHoldVM, tableData])

     useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_MASTER_HOLD_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

      const handleRowDoubleClick = useCallback((entity: MasterHoldEntity) => {
        masterHoldVM.onRowDoubleClick(entity);
    }, [masterHoldVM])

    /*useEffect(() => {
        const handleRightClickMenu = (event:any) => {            
            if(_.isEmpty(selectedRows) || !allowUpdate){                
                return; 
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
          document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, selectedRows])
   

    const moveItemList: MenuItem[] = useMemo(() => {
        const menuItemArray: MenuItem[] = [];

        menuItemArray.push({
            label: 'Move After',
            items:subMenuItemArray
        })
        return menuItemArray;
    },[subMenuItemArray]);

    const handleRowDrag = useCallback( (e: any, updatedRows: StandardTariffCodeEntity[], movedIndex: number, overIndex: number)=>{
        tariffCodeVM.onRowDrag(updatedRows);
    },[tariffCodeVM])*/

    const memoMasterHoldTable = useMemo(() => {
        return (
            <HPHTable
                id='master-hold-table'
                //headerLabel={MasterHoldMaintenanceConstant.Header.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_MASTER_HOLD_COL_DEF.slice()}
                data={transferRowData(tableData) ?? []}
                showPaginator={false}
                editable={false}
                showHeaderIcons={true}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={false}
                selectionMode={false}
                rowSelection={"multiple"}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: MasterHoldEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                ref={gridRef}
            />
        );
    },[tableData, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
            {memoMasterHoldTable}
        </TableWrapper>
        </>;
}

export default memo(MasterHoldTablePanel);
