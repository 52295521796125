import { MasterHoldEntity } from "domain/entity/MasterHold/MasterHoldEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { MasterHoldRepository } from "./MasterHoldRepo";
import { MasterHoldChargeInfoEntity } from "domain/entity/MasterHold/MasterHoldChargeInfoEntity";

/**
 * For template
 * @returns 
 */
export const MasterHoldRepoImpl = (): MasterHoldRepository => {
    const url = '/v1/masterHold';

    const getEntities = async() : Promise<MasterHoldEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
        
    }

    const getEntityByKey = async(key: string): Promise<MasterHoldEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const searchChargeInfo = async(masterHoldId: number): Promise<MasterHoldChargeInfoEntity[]> => {
        const newUrl = url + '/getChargeInfo';
        return await axiosGetData(referenceAxiosInstance, `${newUrl}/${masterHoldId}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createEntity = async(newData: MasterHoldEntity) : Promise<ResponseEntity> => {
        //return await axiosPostData(referenceAxiosInstance, `${url}`, newData);

        const newUrl = url + '/add';
        return await axiosPostData(referenceAxiosInstance, `${newUrl}`, newData).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const updateEntity = async(updatedData: MasterHoldEntity): Promise<ResponseEntity> => {
        //return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);

        const newUrl = url + '/update';
        return await axiosPutData(referenceAxiosInstance, `${newUrl}`, updatedData).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const deleteEntity= async(deleteData: MasterHoldEntity[]): Promise<ResponseEntity> => {
        //return await axiosDeleteData(referenceAxiosInstance, `${url}`, deleteData);

        const newUrl = url + '/delete';
        return await axiosPostData(referenceAxiosInstance, `${newUrl}`, deleteData).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const releaseChargeInfo = async(releaseData: MasterHoldChargeInfoEntity[]): Promise<ResponseEntity> => {
        //return await axiosDeleteData(referenceAxiosInstance, `${url}`, deleteData);

        const newUrl = url + '/release';
        return await axiosPostData(referenceAxiosInstance, `${newUrl}`, releaseData).then(res => {
            return res;
        }).catch(err => {
            return null;
        });
    }

    const holdChargeInfo = async(holdData: MasterHoldChargeInfoEntity[]): Promise<ResponseEntity> => {
        //return await axiosDeleteData(referenceAxiosInstance, `${url}`, deleteData);

        const newUrl = url + '/hold';
        return await axiosPostData(referenceAxiosInstance, `${newUrl}`, holdData).then(res => {
            return res;
        }).catch(err => {
            return null;
        });
    }

    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
        searchChargeInfo: searchChargeInfo,
        releaseChargeInfo: releaseChargeInfo,
        holdChargeInfo: holdChargeInfo
    }
}